import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProblemsList, getCommandData, updateAssistantId } from 'src/pages/AiAnalyse/slice';
import { getIsNoAuth, updateAiHelperIsShow } from '../slice';
import AiHelperIcon from 'src/assets/images/icons/ai_helper.svg';
import styles from './index.module.less';

const AD_AI_HELPER_ID = '广小觉';

const AiHelperButton: React.FC = () => {
  const dispatch = useDispatch();
  const isNoAuth = useSelector(getIsNoAuth);

  useEffect(() => {
    if (location.pathname !== '/adtopic') {
      return;
    }

    dispatch(getCommandData());
    dispatch(getAllProblemsList());
  }, []);

  const onClick = () => {
    dispatch(updateAssistantId(AD_AI_HELPER_ID));
    dispatch(updateAiHelperIsShow(true));
  };

  if (location.pathname !== '/adtopic' || isNoAuth) {
    return null;
  }

  return (
    <div
      className={styles.ai_helper_button}
      role="button"
      tabIndex={0}
      onClick={onClick}
    >
      <AiHelperIcon />
    </div>
  );
};

export default memo(AiHelperButton);
