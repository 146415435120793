import { SupersetClient } from '@superset-ui/core';
import { PwdLoginType } from 'src/pages/Account/types/account';
import httpRequest from '../../../utils/httpRequest';
import { AddSubAccountType } from '../components/SubAccount/types';
// import { LoginType, PwdLoginType, RegisterType, ResetType } from '../types/account';

const getUserInfo = async () => {
  const { json } = await httpRequest.get(`/user/info`);
  return json.ret;
};

const getImg = async (val:any) => {
  const { json } = await httpRequest.get(`/user/image/${val}`);
  return json.ret;
};

const updateUserInfo = async (id:any,key:any,val:any) => {
  const { json } = await httpRequest.pput(`/user/info/${id}?${key}=${val}`);
  return json;
};
const updateUserImg = async (id:any, formData:any) => {
  const res = await SupersetClient.put({
    endpoint: `/extra_server/user/info/${id}`,
    body: formData,
    headers: { Accept: 'application/json' },
  })
  return res;
};
// 验证旧密码
const verifyPwd = async (data: any) => {
  const { json } = await httpRequest.post('/user/pass/check',data);
  return json;
};
// 修改密码
const updatePwd = async (data:any) => {
  const { json } = await httpRequest.put(`/user/pass/update`,data);
  return json;
};
// 验证子账号权限
const verifyChildAuth = async () => {
  const { json } = await httpRequest.post('/user/hasSlaveAccountAuth',null);
  return json;
};
// const verifyPwd = async (phoneNumber: string) => {
//   const { json } = await httpRequest.post(`/user/pass/check`);
//   return json.ret;
// };

const passwordLogin = async (data: PwdLoginType) => {
  const res = await SupersetClient.post({
    endpoint: '/login/',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
  return res;
};

/**
  * @desc 获取子账号列表
  * @date 2024/03/27
  */
const slaveAccount = async () => {
  const { json } = await httpRequest.get('/user/slaveAccount');
  return json;
};

/**
  * @desc 删除子账号
  * @date 2024/03/31
  */
const deleteSubAccount = async (id: number) => {
  const { json } = await httpRequest.delete(`/user/slaveAccount/${id}`);
  return json;
};

/**
  * @desc 新增子账号
  * @date 2024/03/31
  */
const addSubAccount = async (account: AddSubAccountType) => {
  const { json } = await httpRequest.post('/user/slaveAccount', account);
  return json;
};

/**
  * @desc 判断是否有子账号权限
  * @date 2024/03/31
  */
const hasSlaveAccountAuth = async () => {
  const { json } = await httpRequest.post('/user/hasSlaveAccountAuth');
  return json;
};

/**
  * @desc 批量删除子账号
  * @date 2024/03/31
  */
const deleteAllSubAccount = async (list: Array<number>) => {
  const promises = list.map((id: number) => httpRequest.delete(`/user/slaveAccount/${id}`));
  const result = await Promise.all(promises);
  return result;
};

/**
  * @desc 编辑子账号
  * @date 2024/03/31
  */
const editSubAccount = async (data: any) => {
  const { id } = data;
  const formData = new FormData();
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value as any);
  }
  const { json } = await SupersetClient.put({
    endpoint: `/extra_server/user/info/${id}`,
    body: formData,
    headers: { Accept: 'application/json' },
  })
  return json;
};

const api = {
  getImg,
  getUserInfo,
  updateUserInfo,
  updateUserImg,
  verifyPwd,
  updatePwd,
  passwordLogin,
  verifyChildAuth,
  slaveAccount,
  deleteSubAccount,
  addSubAccount,
  hasSlaveAccountAuth,
  deleteAllSubAccount,
  editSubAccount,
};

export default api;
